import { SET_USER, SET_USERS, CREATE_USER } from './users.types';

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER:
           return action.payload;
        case SET_USERS:
           return action.payload;
        case CREATE_USER:
           return action.payload;
        default: return state;
    }
};

export default reducer;