import { Link } from 'react-router-dom'

function OrderTableUser({user}){
    return(
        <div>
            <div className="order-table__top"><Link className="cancel" to={`/admin/editClient/${user.id}`}>Editar Dados Do Cliente</Link></div>
            <div className="order-table__user">
                <div><span>Nome:</span> {user.nome}</div>
                <div><span>Endereço:</span> {user.logradouro}</div>
                <div><span>Numero:</span> {user.numero}</div>
                <div><span>Complemento:</span> {user.complemento}</div>
                <div><span>Telefone:</span> {user.telefone}</div>
                <div><span>Cep:</span> {user.cep}</div>
                <div><span>Cidade:</span> {user.regiao}</div>
                <div><span>Pagamento:</span> {user.pagamento}</div>
            </div>
        </div>
    )
}

export default OrderTableUser;