import App from './App';
import Admin from './components/Admin/Admin';
import AdminHome from './components/Admin/Home/Home';
import Login from './components/Login/Login';
import Signup from './components/Admin/Signup/Signup';
import Dashboard from './components/Admin/Dashboard/Dashboard';
import ProductsAdmin from './components/Admin/Dashboard/Products/Products';
import ClientsAdmin from './components/Admin/Dashboard/Clients/Clients';
import UploadImages from './components/Admin/Dashboard/UploadImages/UploadImages';
import Orders from './components/Admin/Dashboard/Orders/Orders';
import Order from './components/Admin/Dashboard/Order/Order';
import Products from './components/Products/Products';
import ProductsRender from './components/Products/ProductsRender';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
//import ShoppingCart from './components/Products/ShoppingCart/ShoppingCart';
import ClientOrder from './components/Products/ClientOrder/Entry';
import OrderFinish from './components/Products/ShoppingCart/OrderFinish';
import EditPage from './components/Admin/Dashboard/EditPage/EditPage';
import EditClient from './components/Admin/Dashboard/EditClient/EditClient';
import AddProduct from './components/Admin/Dashboard/AddProduct/AddProduct';
import AddClient from './components/Admin/Dashboard/AddClient/AddClient';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";

function AppRouter(){
    return(
    <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<App/>} />
          <Route path="sobre" exact element={<About/>} />
          <Route path="contato" exact element={<Contact/>} />
            <Route path="admin" exact element={<Admin />} >
              <Route index element={<AdminHome />} />
              <Route path="login" exact element={<Login />} />
              <Route path="signup" exact element={<PrivateRoute><Signup /></PrivateRoute>} />
              <Route path="dashboard" exact element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="products" exact element={<PrivateRoute><ProductsAdmin /></PrivateRoute>} />
              <Route path="clients" exact element={<PrivateRoute><ClientsAdmin /></PrivateRoute>} />
              <Route path="upload" exact element={<PrivateRoute><UploadImages /></PrivateRoute>} />
              <Route path="orders" exact element={<PrivateRoute><Orders /></PrivateRoute>} />
              <Route path="order/:id" exact element={<PrivateRoute><Order /></PrivateRoute>} />
              <Route path="edit/:id" exact element={<PrivateRoute><EditPage /></PrivateRoute>} />
              <Route path="editClient/:id" exact element={<PrivateRoute><EditClient /></PrivateRoute>} />
              <Route path="add" exact element={<PrivateRoute><AddProduct /></PrivateRoute>} />
              <Route path="addClient" exact element={<PrivateRoute><AddClient /></PrivateRoute>} />
            </Route>
{/*         <Route path="produtos" exact element={<ProductsRender />} >
              <Route index element={<Products/>} />
              <Route path="checkout/:id" exact element={<ClientOrder/>} />
              <Route path="checkout/orderfinish/:id" exact element={<OrderFinish/>} />
            </Route> */}
        </Routes>
    </BrowserRouter>
    )
}

export default AppRouter;