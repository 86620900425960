import Blank from '../../../img/blank.gif';
import Legumes from '../../../img/feature_img/legumes.png';
import Partners from '../../Partners/Partners';
import ContactForm from '../../ContactForm/ContactForm';

function Main(){
    return(
        <main role="main">
			
				<section className="section">
					<div className="container">
						<div className="section-heading section-heading--center" data-aos="fade">
							<h2 className="__title">Principais <span>Produtos</span></h2>

							<p>Nossos orgânicos respeitam o tempo da safra e época de colheita de cada tipo de alimento.</p>
						</div>

						<div className="feature feature--style-1">
							<div className="__inner">
								<div className="row">
									<div className="col-6 col-sm-4 col-lg-2">
										<div className="__item  text-center" data-aos="fade" data-aos-delay="100" data-aos-offset="100">
											<i className="__ico">
												<img className="img-fluid  lazy" src={Blank} data-src={Legumes} alt="demo" />
											</i>

											<h5 className="__title">Legumes</h5>
										</div>
									</div>

									<div className="col-6 col-sm-4 col-lg-2">
										<div className="__item  text-center" data-aos="fade" data-aos-delay="200" data-aos-offset="100">
											<i className="__ico">
												<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/feature_img/leguminosas.png" alt="demo" />
											</i>

											<h5 className="__title">Leguminosas</h5>
										</div>
									</div>

									<div className="col-6 col-sm-4 col-lg-2">
										<div className="__item  text-center" data-aos="fade" data-aos-delay="300" data-aos-offset="100">
											<i className="__ico">
												<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/feature_img/3.png" alt="demo" />
											</i>

											<h5 className="__title">Frutas</h5>
										</div>
									</div>
									
									<div className="col-6 col-sm-4 col-lg-2">
										<div className="__item  text-center" data-aos="fade" data-aos-delay="400" data-aos-offset="100">
											<i className="__ico">
												<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/feature_img/fried-egg.png" alt="demo" />
											</i>

											<h5 className="__title">Ovos</h5>
										</div>
									</div>
									
									<div className="col-6 col-sm-4 col-lg-2">
										<div className="__item  text-center" data-aos="fade" data-aos-delay="500" data-aos-offset="100">
											<i className="__ico">
												<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/feature_img/raizes.png" alt="demo" />
											</i>

											<h5 className="__title">Raízes</h5>
										</div>
									</div>
									
									<div className="col-6 col-sm-4 col-lg-2">
										<div className="__item  text-center" data-aos="fade" data-aos-delay="600" data-aos-offset="100">
											<i className="__ico">
												<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/feature_img/harmonia.png" alt="demo" />
											</i>

											<h5 className="__title">Harmonia</h5>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section">
					<div className="d-none d-lg-block">
						<img id="bg-img-1" className="img-fluid lazy" src="img/blank.gif" data-src="" alt="demo" />
						
					</div>

					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 col-lg-4">
								<div data-aos="fade-left" data-aos-delay="400" data-aos-duration="500" ddata-aos-offset="100">
									<div className="section-heading">
										<h2 className="__title">Sobre orgânicos  <span>Sítio da Felicidade</span></h2>
									</div>

								</div>
							</div>

							<div className="col-12 my-3 d-lg-none"></div>

							<div className="col-12 col-lg-4  text-center">
								<div data-aos="fade-up" ddata-aos-duration="600" data-aos-offset="100">
									<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/atualizacoes/sobreorganicos.jpeg" alt="sobre organicos" />
								</div>
							</div>

							<div className="col-12 my-3 d-lg-none"></div>

							<div className="col-12 col-lg-4">
								<div data-aos="fade-right" data-aos-delay="400" data-aos-duration="500" ddata-aos-offset="100">
									<p>
									    A ideia é entender onde nós, seres humanos, podemos nos encaixar na natureza. Compreender a vida do solo, a parceria com os animais, o uso sustentável de recursos e o equilíbrio da vida. Isso reflete na qualidade dos alimentos que produzimos ao implementar estas ideias no dia a dia de uma pequena empresa.
										O conceito de comunidade também é algo que cultivamos. Hoje nossa certificação é feita pela ANC - Campinas, uma certificação participativa, onde os produtores fazem a certificação, criando um engajamento e uma rede de acolhimento e apoio.
									</p>

								</div>
							</div>
						</div>
					</div>
				</section>
				
				<section className="section section--no-pt section--no-pb section--gutter">
					<div className="container-fluid px-md-0">
						
						<div className="product-preview product-preview--style-1">
							<div className="__inner">
								<div className="row">
									
									<div className="col-12 col-md-7 col-xl-6">
										<div className="__item">
											<div className="__intro-text">
												<div className="row">
													<div className="col-md-11">
														<h2><span>Produtos</span> Orgânicos</h2>

														<p>
															Alimentos produzidos com respeito ao meio ambiente e qualidade. Sem o uso de agrotóxicos nem qualquer outro tipo de produto que possa vir a causar algum dano à saúde dos consumidores.
														</p>
	
													</div>
												</div>
											</div>
										</div>
									</div>
									
									<div className="col-12 col-md-5 col-xl-3">
										<div className="__item">
											<figure className="__image">
												<img className="lazy" src="img/blank.gif" data-src="img/atualizacoes/produto 1.jpeg" alt="demo" />
											</figure>

											<div className="__content">
												<h3 className="__title"></h3>
											</div>
	
										</div>
									</div>
									
									<div className="col-12 col-md-4 col-xl-3">
										<div className="__item">
											<figure className="__image">
												<img className="lazy" src="img/blank.gif" data-src="img/atualizacoes/produto 2.jpeg" alt="demo" />
											</figure>

											<div className="__content">
												<h3 className="__title"></h3>
											</div>
	
										</div>
									</div>
									
									<div className="col-12 col-md-4 col-xl-3">
										<div className="__item">
											<figure className="__image">
												<img className="lazy" src="img/blank.gif" data-src="img/atualizacoes/produto 3.jpeg" alt="demo" />
											</figure>

											<div className="__content">
												<h3 className="__title"></h3>
											</div>
	
										</div>
									</div>
									
									<div className="col-12 col-md-4 col-xl-3">
										<div className="__item">
											<figure className="__image">
												<img className="lazy" src="img/blank.gif" data-src="img/atualizacoes/produto 4.jpeg" alt="demo" />
											</figure>

											<div className="__content">
												<h3 className="__title"></h3>
											</div>
	
										</div>
									</div>
									
									<div className="col-12 col-xl-6">
										<div className="__item">
											<div className="__content">
												<h2 className="__title"><b>variedade</b>de produtos</h2>
											</div>
	
										</div>
									</div>
									
									<div className="col-12 col-md-7 col-xl-6 offset-xl-3">
										<div className="__item">
											<figure className="__image">
												<img className="lazy" src="img/blank.gif" data-src="img/atualizacoes/produto 5.jpeg" alt="demo" />
											</figure>

											<div className="__content">
												<h3 className="__title"></h3>
											</div>
	
										</div>
									</div>
									
									<div className="col-12 col-md-5 col-xl-3">
										<div className="__item">
											<figure className="__image">
												<img className="lazy" src="img/blank.gif" data-src="img/atualizacoes/produto 6.jpeg"  alt="demo" />
											</figure>

											<div className="__content">
												<h3 className="__title"></h3>
											</div>
	
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
					</div>
				</section>
								
				<section className="section section--review  lazy" data-src="img/review_bg_1.png">
					<div className="container">
						<div className="section-heading section-heading--center" data-aos="fade">
							<h2 className="__title">O que as pessoas dizem sobre <span>Sítio da Felicidade</span></h2>

							<p>Satisfação de clientes</p>
						</div>

						<div className="review review--slider">
							<div className="js-slick" data-slick='{"autoplay": true, "arrows": false, "dots": true, "speed": 1000}'>
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Que descoberta maravilhosa!!! O Sítio Felicidade salvou meu isolamento. Entrega de orgânicos fresquinhos duas vezes por semana. E ainda tem a simpatia da Luísa e do Felipe! Obrigada!!</i>
										</p>
									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Cris W.</strong></span>
											
										</div>
									</div>
								</div>
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Excelente atendimento. Produtos ótimos. Qualidade orgânica da melhor qualidade. </i>
										</p>

									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Maira Luiza K.</strong></span>
											
										</div>
									</div>
								</div>
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Orgânicos frescos, ótimo atendimento e entregas pontuais! Feliz por acompanhar o crescimento do Sítio da Felicidade em meio a tudo o que estamos vivendo em 2020! Viva o pequeno produtor! Sucesso! </i>
										</p>

									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Carol Y.</strong></span>
											
										</div>
									</div>
								</div>
								
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Com a pandemia e o aumento do consumo de alimentação preparada em casa, o Sítio da Felicidade se tornou um parceiro essencial, na tentativa de consumir comidas mais saudáveis, e principalmente sem agrotóxicos! Isso nos ajudou muito a cuidar e manter a saúde em dia, mesmo com todas as restrições que a pandemia nos trouxe. Agradecemos de coração pelo excelente atendimento, parceria e coisas gostosas e fresquinhas trazidas para nosso lar! </i>
										</p>

									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Juliana S.</strong></span>
											
										</div>
									</div>
								</div>
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Muito bom receber tudo em casa! Fresquinho e natural! Já faz parte da nossa rotina! As verduras e legumes são ótimos!</i>
										</p>

									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Daniela D.</strong></span>
											
										</div>
									</div>
								</div>
								
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Experimentar os produtos orgânicos do Sitio da Felicidade, foi a melhor opção para minha vida mais saudável.</i>
										</p>
										<p>
											<i>Os produtos além de otima qualidade, tenho um ótimo atendimento, entregas sem problemas e aprendi com eles receitas que simplesmente ficam demais de boas, contribuindo p para minha saúde e mais depois que comecei utilizar os produtos orgânicos reduzi uns quilinhos que havia ganho no início da quarentena.</i>
										</p>
										<p>
											<i>As verduras são maravilhosas e digo mais duram muito mais que porque orgânicas. Saber que está se alimentando sem conservantes, agrotóxicos faz diferença, principalmente agora que é tempo de se alimentar bem.</i>
										</p>
										<p>
											<i>Parabéns produtores do Sítio da Felicidade, estou muito satisfeita com seus produtos.</i>
										</p>

									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Cibele Aparecida G.</strong></span>
											
										</div>
									</div>
								</div>
								
								
								<div className="review__item">
									<div className="review__item__text">
										<p>
											<i>Os alimentos são de excelente qualidade, fresquinhos, maços sempre generosos.</i>
										</p>
										<p>
											<i>O atendimento é gentil, pontual. Comecei a comprar com Felipe durante a pandemia, virei cliente permanente.</i>
										</p>

									</div>

									<div className="review__item__author  d-table">
										<div className="d-table-cell align-middle">
											<div className="review__item__author-image">
												<img className="circled" src="img/ava.png" alt="ava" />
											</div>
										</div>

										<div className="d-table-cell align-middle">
											<span className="review__item__author-name"><strong>Rita C.</strong></span>
											
										</div>
									</div>
								</div>
								

							</div>
						</div>
						
					</div>
				</section>
				<Partners />
				<ContactForm type='form-home'/>
			</main>
    )
}

export default Main