import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import Logo from "../../../img/logo_gdn_new.jpeg";

function Header() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState(false);
  async function handleLogout() {
    try {
      await logout();
    } catch {
      setError("Failed to logout");
    }
  }
  return currentUser && <HeaderContent handleLogout={handleLogout} />;
}

function HeaderContent({ handleLogout }) {
  return (
    <header className="header-admin">
      <Link to="/admin/dashboard">
        <img className="img-fluid" src={Logo} alt="demo" />
      </Link>
      <ul className="header-admin__navbar">
        <li>
            <Link to="/admin/dashboard">
                 Dashboard
            </Link>
        </li>
        <li>
          <Link to="/admin/upload">
            <i className="fontello-vkontakte"></i> Imagens
          </Link>
        </li>
        <li>
          <Link to="/admin/products">
              <i className="fontello-truck"></i> Produtos
          </Link>
        </li>
        <li>
          <Link to="/admin/clients">
              <i className="fontello-truck"></i> Clients
          </Link>
        </li>
        <li>
          <Link to="orders">
            <i className="fontello-shopping-bag"></i> Pedidos
          </Link>
        </li>
        <li>
          <Link to="signup">
            <i className="fontello-profile"></i> + User
          </Link>
        </li>
        <li onClick={() => handleLogout()}>
          <Link to="#">
            <i className="fontello-down"></i> Sair
          </Link>
        </li>
      </ul>
    </header>
  );
}

export default Header;
