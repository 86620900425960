function OrderBag({products, setProducts}){
    const handleChange = (e, index) => {
      e.preventDefault()

      const value = e.target.value;
      if(!value) return;

      const name = e.target.name;
      let orderClone = [...products]

      orderClone[index][`${name}`] = value

      setProducts(orderClone)
    }

    const handleRemove = (e, index) => {
        e.preventDefault()
        let orderClone = [...products]
        orderClone[index][`removed`] = true;
        //orderClone[index]['preco'] = 0;
        setProducts(orderClone)
    }
    return(
        <>
          <div className="order-table__bag">
                {products?.map((order, index) => (
                    <div className={`order-table__product ${order.removed ? 'class-removed' : ''}`} key={order.id}>
                        <div className="order-table__box">
                            <div><img className="order-table__image" src={order.image_url}/></div>
                            <div className="order-table__info">
                                <div><span>Produto:</span> {order.produto}</div>
                                <div><span>Preço:</span> R$ <input name="preco" onChange={(e) => {handleChange(e, index)}} type="text" placeholder={order.preco}/></div>
                                <div><span>Quantidade:</span> <input name="qtd" onChange={(e) => {handleChange(e, index)}} type="text" placeholder={order.qtd}/></div>
                                <div><span>Unidade:</span> {order.unidade}</div> 
                            </div>
                        </div>
                        <div className="order-table__total"><span>R$ {order.preco * order.qtd}</span></div>
                        <div onClick={(e) => handleRemove(e, index)}>Remover</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default OrderBag;