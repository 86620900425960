import { database } from '../../firebase/firebase';
import { ref, onValue, set, push, child, get } from "firebase/database";
import { GET_CHECKOUT_BY_ID, RESET_CHECKOUT, UPDATE_CHECKOUT_STATUS, UPDATE_ORDER, SET_MESSAGE, UPDATE_PRODUCTS } from './checkout.types';
import moment from 'moment';

export const get_checkout_by_id = (checkout) => {
  return {
      type: GET_CHECKOUT_BY_ID,
      payload: checkout
  };
};

export const update_order_status = (order) => {
  return {
      type: UPDATE_CHECKOUT_STATUS,
      payload: order
  };
};

export const set_message = (message) => {
  return {
      type: SET_MESSAGE,
      payload: message
  };
};

export const update_order = (updateOrder) => {
  return {
      type: UPDATE_ORDER,
      payload: updateOrder
  };
};


export const update_products = (products) => {
  return {
      type: UPDATE_PRODUCTS,
      payload: products
  };
};

export const reset_checkout = () => {
  return {
      type: RESET_CHECKOUT,
      payload: {}
  };
};

export const start_get_checkout_by_id = (id) => {
  return (dispatch) => {
    let data;
    const checkoutId = ref(database, 'checkouts/' + id);
    onValue(checkoutId, (snapshot) => {
      data = {}
      data = {
        id,
        ...snapshot.val()
      }
      return dispatch(get_checkout_by_id(data))
    });
  }
}

export const start_update_order_status = (id, order, status) => {
  return (dispatch) => {
    set(ref(database, 'checkouts/' + id), {...order, status});
    return dispatch(update_order_status({...order, status}))
  }
}

export const start_set_message = (id, message) => {
  return (dispatch) => {
    set(ref(database, 'checkouts/' + id + '/messages/'), message);
    return dispatch(set_message(message))
  }
}

export const start_update_order = (updateOrder) => {
  return (dispatch) => {
    set(ref(database, 'checkouts/' + updateOrder.id), updateOrder);
    return dispatch(update_order(updateOrder))
  }
}

export const start_update_products = (orderId, products) => {
  return (dispatch) => {
    set(ref(database, 'checkouts/' + orderId + '/prods'), products);
    return dispatch(update_products(products))
  }
}

export const start_update_order_with_user = (phone, updateOrder) => {
    if(!phone) return;
    return (dispatch) => {
      const usersRef = ref(database, 'users/');
      var users = [];
      var user = [];
      get(usersRef).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            users.push({
              id: childSnapshot.key,
              ...childSnapshot.val()
            })
          })
          user = users.filter((user) => user.telefone == phone)
          console.log(user.length, user, users)
          if(user.length === 0){
            let userId = push(child(ref(database), 'users')).key;
            user.push({
              id: userId
            })
            set(ref(database, 'users/' + userId), {telefone: phone});
          }
          set(ref(database, 'checkouts/' + updateOrder.id), {
            ...updateOrder,
            userId : user[0].id,
            messages: [
              {
                sender: 'comprador',
                content: 'Olá, recebemos o seu pedido. Em breve entraremos em contato pelo whatsapp para concluírmos a venda.',
                createdAt: moment().unix()
              }
            ],
            status: 'revision'
          })
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      })
      return dispatch(update_order(updateOrder))
    }
}

