import { CLIENTS } from './clients.types';

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLIENTS.SET_CLIENTS:
            return action.payload;
        case CLIENTS.ADD_CLIENTS:
            return action.payload;
        case CLIENTS.ADD_CLIENT:
            return state;
        case CLIENTS.UPDATE_CLIENT:
            return state.map((client) => {
                if (client.id === action.payload.id) {
                  return {
                    ...client,
                    ...action.payload
                  }
                } else {
                  return client;
                }
              })
        default: return state;
    }
};

export default reducer;