import db, {database} from '../../firebase/firebase';
import { set, ref, onValue, remove} from "firebase/database";
import { IMAGES } from './images.types';

export const set_all_products = (products) => {
  return {
      type: IMAGES.SET_IMAGES,
      payload: products
  };
};

export const set_images = (images) => {
    return {
        type: IMAGES.SET_IMAGES,
        payload: images
    };
};

export const add_image = (image) => {
  return {
      type: IMAGES.ADD_IMAGE,
      payload: image
  };
};

export const remove_image = (id) => {
  return {
      type: IMAGES.REMOVE_IMAGE,
      payload: id
  };
};

export const start_add_image = (image) => {
  return async (dispatch) => {
    await set(ref(database, `images/${image.id}`), image);
    return dispatch(add_image(image))
  }
}

export const start_remove_image = (id) => {
  return async (dispatch) => {
    const imageRef = ref(database, 'images/' + id);
    remove(imageRef)  
    return dispatch(remove_image(id))
  }
}


export const start_set_images = () => {
  return async (dispatch) => {
    const imagesRef = ref(database, 'images/');
    onValue(imagesRef, (snapshot) => {
      const images = [];
      snapshot.forEach((childSnapshot) => {
        images.push({
          ...childSnapshot.val()
        })
      })
      return dispatch(set_images(images))
    });
  }
}