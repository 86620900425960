import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { reset_checkout } from '../../../../redux/Checkout/checkout.actions';
import { start_get_checkouts, start_remove_checkout } from '../../../../redux/Checkouts/checkouts.actions';
import statusFilter from '../../../../redux/Selectors/status';
import moment from 'moment';
import './Orders.css'

function Orders(){
    const dispatch = useDispatch()
    const checkoutsState = useSelector(state => state.checkouts)
    const [checkouts, setCheckouts] = useState([])
    let total = 0;

    if(checkouts.length !== 0){
        let receita = []
        checkouts.filter(({status}) => status === 'done').map(({total}) => receita.push(total))
        total = receita.reduce(function(total, numero){
              return total + numero;
        }, 0);
    }

    useEffect(() => {
        dispatch(reset_checkout())        
        if(checkoutsState !== checkouts){
            setCheckouts(checkoutsState)
        }
    },[checkoutsState])
    return (
        <div className="dashboard">
            <div className="billboard-dashboard">
                <div>
                    <span>1,180</span>Views
                </div>
                <div>
                    <span>{checkouts.length}</span>Vendas
                </div>
                <div>
                    <span>100</span>Comentários
                </div>
                <div>
                    <span>R${total}</span>Receita
                </div>
            </div>
            <div className="recent-orders">
                <div className="recent-orders__top">
                    <span>Pedidos Recentes</span>
                    <button>Ver Todos</button>
                </div>
                <div>
                <table className="table-dashboard">
                    <thead>
                    <tr>
                        <th>Pedido</th>
                        <th>Data</th>
                        <th>Preço</th>
                        <th>Pagamento</th>
                        <th>Status</th>
                        <th>Editar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {checkouts?.filter(({status}) => status === 'done').map((checkout) => (
                    <tr key={checkout.id}>
                        <td>{checkout.id}</td>
                        <td>{moment.unix(checkout.createdAt).format("DD/MM/YYYY")}</td>
                        <td><span className="table-dashboard__total">R$ {checkout?.total}</span></td>
                        <td>{checkout?.pagamento}</td>
                        <td>{statusFilter(checkout.status, null).component}</td>
                        <TdComponent status={checkout.status} checkout={checkout} dispatch={dispatch}/>
                    </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function TdComponent({status, checkout, dispatch}){
    const handleRemove = () => {
        dispatch(start_remove_checkout(checkout.id))
    }
    if(status === 'cart'){
        return <td style={{cursor: 'pointer'}} onClick={handleRemove}>Excluir</td>
    }else{
        return <td><Link to={`/admin/order/${checkout.id}`}>Editar</Link></td>
    }
}

export default Orders
