import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import usersReducer from './Users/users.reducer';
import productsReducer from './Products/products.reducer';
import clientsReducer from './Clients/clients.reducer';
import checkoutsReducer from './Checkouts/checkouts.reducer';
import checkoutReducer from './Checkout/checkout.reducer';
import filtersReducer from './Filters/filters.reducer';
import imagesReducer from './Images/images.reducer';
import messagesReducer from './Messages/messages.reducer';

const rootReducer = combineReducers({
    users: usersReducer,
    products: productsReducer,
    clients: clientsReducer,
    checkouts: checkoutsReducer,
    checkout: checkoutReducer,
    filters: filtersReducer,
    images:imagesReducer,
    messages: messagesReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store;