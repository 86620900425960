function Partners(){
    return(
        <section className="section">
					<div className="container">
						<div className="section-heading section-heading--center" data-aos="fade">
							<h2 className="__title">Parceiros</h2>

							<p>Texto sobre os nossos parceiros.</p>
						</div>

						<div className="partners-list">
							<div className="js-slick"
								 data-slick='{
								 "autoplay": true,
								 "arrows": false,
								 "dots": true,
								 "speed": 1000,
								 "responsive": [
									{
										"breakpoint":576,
										"settings":{
											"slidesToShow": 2
										}
									},
									{
										"breakpoint":767,
										"settings":{
											"slidesToShow": 3
										}
									},
									{
										"breakpoint":991,
										"settings":{
											"slidesToShow": 4
										}
									},
									{
										"breakpoint":1199,
										"settings":{
											"autoplay": false,
											"dots": false,
											"slidesToShow": 5
										}
									}
								]}'>
		
								<div className="__item">
									<img className="img-fluid m-auto" src="img/partners_img/logo_anc11.png" alt="Anc" />
								</div>
		
								<div className="__item">
									<img className="img-fluid m-auto" src="img/partners_img/mais_caipira.png" alt="mais caipira" style={{maxWidth:'100px'}}/>
								</div>

								<div className="__item">
									<img className="img-fluid m-auto" src="img/partners_img/aao.png" alt="aao" />
								</div>

							</div>
						</div>
					</div>
				</section>
    )
}

export default Partners;