import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { start_add_message } from '../../redux/Messages/messages.actions';

function ContactForm({type}){
    const dispatch = useDispatch()

    const[form,setForm] = useState({});
    const[msg, setMsg] = useState('')

    const handleChange = (e) => {
        setMsg('')
        const {value} = e.target
        const {name} = e.target
        let formCopy = {...form}
        formCopy[`${name}`] = value;
        setForm({...formCopy, type})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(form.name && form.email && form.message){
            dispatch(start_add_message(form))
            setMsg('Mensagem enviada com sucesso!')
        }else{
            setMsg('Preencha todos os campos primeiro...')
        }
    }
    return(
        <section className="section section--dark-bg">
					<div className="container">
						<div className="section-heading section-heading--center section-heading--white" data-aos="fade">
							<h2 className="__title">Deixe <span>uma mensagem</span></h2>

							<p>Ficaremos muito felizes em receber sua mensagem.</p>
						</div>
						<form id="inputForm" className="contact-form js-contact-form" action="" data-aos="fade" onSubmit={(e) => handleSubmit(e)}>
                        {msg && <div>{msg}</div>}
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="input-wrp">
										<input id="inputName" className="textfield" name="name" type="text" placeholder="Nome" onChange={(e) => handleChange(e)}/>
									</div>
								</div>

								<div className="col-12 col-md-6">
									<div className="input-wrp">
										<input id="inputEmail" className="textfield" name="email" type="text" placeholder="E-mail" onChange={(e) => handleChange(e)}/>
									</div>
								</div>
							</div>

							<div className="input-wrp">
								<textarea id="inputMessage" className="textfield" name="message" placeholder="Comentário"onChange={(e) => handleChange(e)}></textarea>
							</div>

							<button className="custom-btn custom-btn--medium custom-btn--style-3 wide" type="submit" role="button">Enviar</button>

							<div id="form_note" className="form__note"></div>
						</form>
					</div>
				</section>
    )
}

export default ContactForm;