import Header from '../Header/Header';

function Contact(){
    return(
        <>
            <Header />
            <div id="hero" className="jarallax" data-speed="0.7" data-img-position="50% 55%" style={{backgroundImage: 'url(img/home_img/img_1.jpg)'}}>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-7">
							<h1 className="__title"><span>Sítio da Felicidade</span> Entre em Contato</h1>

							<p>
								Ficaremos felizes em ajuda-lo. Entre em contato conosco.
							</p>
						</div>
					</div>
				</div>
			</div>
            <main role="main">
				<section className="section">
					<div className="container">
						
						<div className="company-contacts  text-center">
							<div className="__inner">
								<div className="row justify-content-around">
									
									<div className="col-12 col-md-4 col-lg-3">
										<div className="__item">
											<i className="__ico fontello-location"></i>

											<h4 className="__title">Endereço</h4>

											<p>
												Cosmópolis, São Paulo - SP
											</p>
										</div>
									</div>
									
									<div className="col-12 col-md-4 col-lg-3">
										<div className="__item">
											<i className="__ico fontello-phone"></i>

											<h4 className="__title">Telefone</h4>

											<p>
												+55 19 99600 7555
											</p>
										</div>
									</div>
									
									<div className="col-12 col-md-4 col-lg-3">
										<div className="__item">
											<i className="__ico fontello-mail-1"></i>

											<h4 className="__title">e-mail</h4>

											<p><a href="mailto:sdafelicidade@gmail.com">sdafelicidade@gmail.com</a></p>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
					</div>
				</section>
				
				<section className="section section--dark-bg  section--contacts">
					<div className="container">
						<div className="row justify-content-end">
							<div className="col-12 col-md-6">

								<div className="row justify-content-end">
									<div className="col-md-11">
										<div className="section-heading section-heading--white">
											<h2 className="__title">Entre <span>em contato</span></h2>

											<p>Ficaremos felizes em ajuda-lo</p>
										</div>

										<form id="inputForm" className="contact-form js-contact-form" action="#">
											<div className="input-wrp">
												<input id="inputName" className="textfield" name="name" type="text" placeholder="Nome" />
											</div>

											<div className="input-wrp">
												<input id="inputEmail" className="textfield" name="email" type="text" placeholder="E-mail" />
											</div>

											<div className="input-wrp">
												<textarea id="inputMesage" className="textfield" name="message" placeholder="Mensagem"></textarea>
											</div>

											<button className="custom-btn custom-btn--medium custom-btn--style-3 wide" type="submit" role="button">Enviar</button>

											<div id="form_note" className="form__note"></div>
										</form>
									</div>
								</div>

							</div>
						</div>
					</div>
					 <div className="row no-gutters">
						<div className="col-md-6  map-container map-container--left"> 
							
							<div className="contact-bg"></div>
						</div>
					</div> 
					
					
				</section>
				
			</main>
			
			<footer id="footer" className="footer--style-1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-auto">
							<div className="footer__item">
								<a className="site-logo" href="index.html">
									<img className="img-fluid  lazy" src="img/blank.gif" data-src="img/logogdn.jpg" alt="demo" />
								</a>
							</div>
						</div>

						<div className="col-12 col-sm">
							<div className="row align-items-md-center no-gutters">
								<div className="col-12 col-md">
									<div className="footer__item">
										<address>
											<p>
												Sítio da Felicidades, Cosmópolis, São Paulo - SP
											</p>

											<p>
												Cel | Whatsapp | +55 19 99600 7555 <br/>
												<a href="mailto:sdafelicidade@gmail.com">sdafelicidade@gmail.com</a>
											</p>
										</address>
									</div>
								</div>

								<div className="col-12 col-md-auto">
									<div className="footer__item">
										<div className="social-btns">
											<a href="#"><i className="fontello-twitter"></i></a>
											<a href="#"><i className="fontello-facebook"></i></a>
											<a href="https://www.instagram.com/sitio.da.felicidade/"><i className="fontello-linkedin-squared"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-5 col-xl-4 offset-xl-1">
							<iframe className="mj-w-res-iframe" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://app.mailjet.com/widget/iframe/6bSm/Gip" width="100%"></iframe>
							
						</div>
					</div>

					<div className="row flex-lg-row-reverse">
						<div className="col-12 col-lg-6">
							<div className="footer__item">
								<nav id="footer__navigation" className="navigation  text-lg-right">
									<ul>
										<li className="active"><a href="index.html">Home</a></li>
										<li><a href="about.html">Sobre</a></li>
										<li><a href="contacts.html">Contato</a></li>
									</ul>
								</nav>
							</div>
						</div>

						<div className="col-12 col-lg-6">
							<div className="footer__item">
								<span className="__copy">© 2019 Agro. All rights reserved. Created by <a className="__dev" href="https://www.facebook.com/tbrobr" target="_blank">raulbarbz</a></span>
							</div>
						</div>
					</div>
				</div>
			</footer>
            </>
    )
}

export default Contact;