import { ADD_CHECKOUT, GET_CHECKOUTS, CLOSE_ORDER, REMOVE_ORDER, REMOVE_CHECKOUT, RESET_CHECKOUTS } from './checkouts.types';

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_CHECKOUTS:
            return action.payload
        case ADD_CHECKOUT:
                return [...state, action.payload]
        case GET_CHECKOUTS:
            return action.payload;
        case REMOVE_ORDER:
            return state
        case REMOVE_CHECKOUT:
            const removeCheckout = state.filter(({id}) => id !== action.payload.checkoutId)
            return removeCheckout;
        case CLOSE_ORDER:
            return state
        default: return state;
    }
};

export default reducer;