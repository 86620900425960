import {database} from '../../firebase/firebase';
import { ref, onValue, set, push, child, remove } from "firebase/database";
import { PRODUCTS } from './products.types';
import moment from 'moment';


export const set_all_products = (products) => {
  return {
      type: PRODUCTS.SET_PRODUCTS,
      payload: products
  };
};

export const set_products = (products) => {
    return {
        type: PRODUCTS.SET_PRODUCTS,
        payload: products
    };
};

export const add_products = (products) => {
  return {
      type: PRODUCTS.ADD_PRODUCTS,
      payload: products
  };
};

export const add_product = (product) => {
  return {
      type: PRODUCTS.ADD_PRODUCT,
      payload: product
  };
};

export const update_product = (update) => {
  return {
      type: PRODUCTS.UPDATE_PRODUCT,
      payload: update
  };
};


export const start_add_product = (product) => {
  return async (dispatch) => {
    let createdAt = moment().unix()
    const id = push(child(ref(database), 'products')).key;
    const productRef = {
      id,
      createdAt,
      ...product
    }
    await set(ref(database, 'products/' + id), productRef);  
    return dispatch(add_product(productRef))
  }
}

export const start_set_all_products = () => {
  return async (dispatch) => {
    const productsRef = ref(database, 'products/');
    onValue(productsRef, (snapshot) => {
      const products = []
      snapshot.forEach((childSnapshot) => {
        products.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      return dispatch(set_all_products(products))
    });
  }
}

export const start_set_products = () => {
  return async (dispatch) => {
    const productsRef = ref(database, 'products/');
    onValue(productsRef, (snapshot) => {
      let products = []
      snapshot.forEach((childSnapshot) => {
        products.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      products = products.filter((product) => product.status === 'true')
      return dispatch(set_all_products(products))
    });
  }
}

export const start_remove_product = (prodId) => {
  return async (dispatch) => {
    const productsRef = ref(database, 'products/' + prodId);
    remove(productsRef)  
    return
  }
}

export const start_update_product = (update) => {
  return async (dispatch) => {
    await set(ref(database, 'products/' + update.id), update);
    return dispatch(update_product(update))
  }
}

const prods = [
  {
    "produto": "Abacate",
    "categoria": "Fruta",
    "preco": 7,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "abacate, fruta, verde, doce, salgado, guacamole",
    "unidade": "500g"
  },
  {
    "produto": "Abacaxi unid",
    "categoria": "Fruta",
    "preco": 16,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "abacaxi, amarelo, ácido, suco, azedo, doce, salgado",
    "unidade": "unid"
  },
  {
    "produto": "Abóbora Cambotiá",
    "categoria": "Legume",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "Abóbora, cambotiá, laranja, sopa, japonesa, preta, salgado",
    "unidade": "1kg"
  },
  {
    "produto": "Abóbora de pescoço (madura)",
    "categoria": "Legume",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "Abóbora, pescoço, doce, laranja, pedaço, brasileira",
    "unidade": "1kg"
  },
  {
    "produto": "Abóbora Buttlenut",
    "categoria": "Legume",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "Abóbora, buttlenut, cinza, laranja, sopa, salgado",
    "unidade": "1kg"
  },
  {
    "produto": "Abóbora Paulista",
    "categoria": "Legume",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "Abóbora, paulista, laranja, verde, sopa, salgado",
    "unidade": "1kg"
  },
  {
    "produto": "Abobrinha Brasileira (verde)",
    "categoria": "Legume",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "Abobrinha, verde, brasileira, salgado, pescoço",
    "unidade": "1kg"
  },
  {
    "produto": "Abobrinha Italiana",
    "categoria": "Legume",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "abobrinha, italiana, verde, branca, zucchini, salgado",
    "unidade": "1kg"
  },
  {
    "produto": "Acelga",
    "categoria": "Hortaliça",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "acelga, hortaliça, refogada, yakisoba, salgado",
    "unidade": "maço"
  },
  {
    "produto": "Açúcar mascavo",
    "categoria": "Mercearia",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "sem categoria",
    "unidade": "1kg"
  },
  {
    "produto": "Agrião",
    "categoria": "Hortaliça",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "agrião, água, ardido, salada, salgado",
    "unidade": "maço"
  },
  {
    "produto": "Agrião da terra",
    "categoria": "Hortaliça",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "agrião, terra, ardido, salada, salgado",
    "unidade": "maço"
  },
  {
    "produto": "Alecrim",
    "categoria": "Tempero",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alecrim, tempero, frango, carne, verde, italiano",
    "unidade": "maço"
  },
  {
    "produto": "Alecrim desidratado",
    "categoria": "Tempero",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alecrim, tempero, frango, carne, verde, italiano",
    "unidade": "10g"
  },
  {
    "produto": "Alface americana",
    "categoria": "Hortaliça",
    "preco": 5.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alface, hortaliça, americana, crocante",
    "unidade": "maço"
  },
  {
    "produto": "Alface crespa",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alface, hortaliça, crespa, durável",
    "unidade": "maço"
  },
  {
    "produto": "Alface crespa roxa",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alface, hortaliça, crespa, roxa",
    "unidade": "maço"
  },
  {
    "produto": "Alface lisa",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alface, lisa, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Alface mimosa",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alface, mimosa, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Alho",
    "categoria": "Tempero",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alho, tempero, salgado, cabeça, dente,",
    "unidade": "100g"
  },
  {
    "produto": "Alho caipirão",
    "categoria": "Tempero",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "alho, tempero, salgado, cabeça, dente,",
    "unidade": "100g"
  },
  {
    "produto": "Alho poró",
    "categoria": "Hortaliça",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "alho, poró, porró, tempero, talo",
    "unidade": "maço"
  },
  {
    "produto": "Almeirão",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "almeirão, hortaliça, verde, panc",
    "unidade": "maço"
  },
  {
    "produto": "Almeirão pão açúcar",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "almeirão, hortaliça, verde, pão, açúcar",
    "unidade": "maço"
  },
  {
    "produto": "Almeirão selvagem",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "almeirão, hortaliça, roxo, selvagem, panc",
    "unidade": "maço"
  },
  {
    "produto": "Ameixa",
    "categoria": "Fruta",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "fruta, vermelha, doce, aguada, ameixa, fresca",
    "unidade": "400g"
  },
  {
    "produto": "Amendoim cru",
    "categoria": "Mercearia",
    "preco": 18,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "amendoim, nuts, nozes, manteiga, vegana, calórico, cru",
    "unidade": "300g"
  },
  {
    "produto": "Amora",
    "categoria": "Fruta",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "fruta, amora, roxa",
    "unidade": "130g"
  },
  {
    "produto": "Arroz agulhinha Branco",
    "categoria": "Mercearia",
    "preco": 16,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "arroz, tradicional, branco, beneficiado, grão",
    "unidade": "1kg"
  },
  {
    "produto": "Arroz agulhinha Integral",
    "categoria": "Mercearia",
    "preco": 16,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "arroz, integral, natural, grão",
    "unidade": "1kg"
  },
  {
    "produto": "Arroz agulhinha Negro",
    "categoria": "Mercearia",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "arroz, negro, novidade, grão",
    "unidade": "500g"
  },
  {
    "produto": "Arroz cateto integral",
    "categoria": "Mercearia",
    "preco": 17,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "arroz, cateto, integral, saudável",
    "unidade": "1kg"
  },
  {
    "produto": "Atemóia",
    "categoria": "Fruta",
    "preco": 17,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "fruta, verde, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Avocado",
    "categoria": "Fruta",
    "preco": 18,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "avocado, fruta, verde, doce, salgado, guacamole",
    "unidade": "1kg"
  },
  {
    "produto": "Azedinha",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "azedinha, hortaliça, verde, almeirão, limão",
    "unidade": "maço"
  },
  {
    "produto": "Babosa",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "babosa, beleza, cosmético, saudável, cicatrizador",
    "unidade": "Folha"
  },
  {
    "produto": "Banana chips",
    "categoria": "Mercearia",
    "preco": 6.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "banana, chips, frita, salgada, pacote",
    "unidade": "70g"
  },
  {
    "produto": "Banana maçã",
    "categoria": "Fruta",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "banana, doce, maçã",
    "unidade": "1kg"
  },
  {
    "produto": "Banana nanica",
    "categoria": "Fruta",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "banana, nanica, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Banana nanica verde",
    "categoria": "Fruta",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "banana, nanica, doce, biomassa",
    "unidade": "1kg"
  },
  {
    "produto": "Banana passa",
    "categoria": "Fruta",
    "preco": 7.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "banana, passa, seca, doce, saudável",
    "unidade": "150g"
  },
  {
    "produto": "Banana prata",
    "categoria": "Fruta",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "banana, prata, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Banana prata verde",
    "categoria": "Fruta",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "banana, prata, doce, biomassa",
    "unidade": "1kg"
  },
  {
    "produto": "Batata chips",
    "categoria": "Mercearia",
    "preco": 8.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "batata, chips, frita, salgada, pacote",
    "unidade": "30g"
  },
  {
    "produto": "Batata doce",
    "categoria": "Hortaliça",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "batata, doce, branca, saudável",
    "unidade": "1kg"
  },
  {
    "produto": "Batata doce rosa",
    "categoria": "Hortaliça",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "batata, doce, branca, saudável",
    "unidade": "1kg"
  },
  {
    "produto": "Batata doce roxa",
    "categoria": "Hortaliça",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "batata, doce, roxa, saudável",
    "unidade": "1kg"
  },
  {
    "produto": "Batata doce salmão",
    "categoria": "Hortaliça",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "batata, doce, salmão, laranja, saudável",
    "unidade": "1kg"
  },
  {
    "produto": "Batata inglesa amarela",
    "categoria": "Hortaliça",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "batata, inglesa, amarela, tradicional",
    "unidade": "1kg"
  },
  {
    "produto": "Batata inglesa asterix",
    "categoria": "Hortaliça",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "batata, inglesa, rosada, tradicional, purê",
    "unidade": "1kg"
  },
  {
    "produto": "Batata Yacon",
    "categoria": "Hortaliça",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "batata, adocicada, crocante, aguada, saudável",
    "unidade": "1kg"
  },
  {
    "produto": "Berinjela",
    "categoria": "Hortaliça",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "berinjela, preta, macia",
    "unidade": "1kg"
  },
  {
    "produto": "Berinjela bilbo rajada",
    "categoria": "Hortaliça",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "berinjela, rajada, bilbo, pequena, roxa, branca, macia",
    "unidade": "1kg"
  },
  {
    "produto": "Berinjela niobe",
    "categoria": "Hortaliça",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "berinjela, roxa, pequena, macia, niobe",
    "unidade": "1kg"
  },
  {
    "produto": "Beterraba",
    "categoria": "Hortaliça",
    "preco": 8.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "beterraba, doce, salgado, vermelha, roxa",
    "unidade": "maço"
  },
  {
    "produto": "Bok Choy (acelga chinesa)",
    "categoria": "Legume",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "bok, choy, acelga, chinesa, couve, salgada, novidade",
    "unidade": "maço"
  },
  {
    "produto": "Brócolis Ninja",
    "categoria": "Legume",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "brócolis, ninja, cabeça, verde, tradicional",
    "unidade": "maço"
  },
  {
    "produto": "Brocolis ramoso",
    "categoria": "Legume",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "brócolis, ramoso, verde",
    "unidade": "maço"
  },
  {
    "produto": "Café Moido Torrado",
    "categoria": "Mercearia",
    "preco": 35,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "café, moído, torrado, manhã, pequi",
    "unidade": "500g"
  },
  {
    "produto": "Capuchinha",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "capuchinha, panc, flor, verde, vermelho",
    "unidade": "maço"
  },
  {
    "produto": "Caqui mole 4 unid",
    "categoria": "Fruta",
    "preco": 7,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "Caqui, mole, vermelho, doce",
    "unidade": "cx"
  },
  {
    "produto": "Caqui rama forte  6 unid",
    "categoria": "Fruta",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "Caqui, duro, vermelho, doce",
    "unidade": "cx"
  },
  {
    "produto": "Caruru",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "Caruru, panc, verde, roxo",
    "unidade": "maço"
  },
  {
    "produto": "Castanha de baru",
    "categoria": "Mercearia",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "castanha, baru, dura, crocante",
    "unidade": "100g"
  },
  {
    "produto": "Castanha de caju",
    "categoria": "Mercearia",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "castanha, caju, dura, crocante",
    "unidade": "100g"
  },
  {
    "produto": "Castanha do Pará",
    "categoria": "Mercearia",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "castanha, pará, dura, crocante",
    "unidade": "100g"
  },
  {
    "produto": "Catalônia",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "catalônia, panc, verde, amarga",
    "unidade": "maço"
  },
  {
    "produto": "Cebola",
    "categoria": "Tempero",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "cebola, branca, amarela, tempero, raiz",
    "unidade": "1kg"
  },
  {
    "produto": "Cebola aperitivo (pequena)",
    "categoria": "Tempero",
    "preco": 7,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "cebola, branca, amarela, tempero, raiz",
    "unidade": "1kg"
  },
  {
    "produto": "Cebola com rama",
    "categoria": "Tempero",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "cebola, branca, amarela, tempero, rama, verde, raiz",
    "unidade": "maço"
  },
  {
    "produto": "Cebola roxa",
    "categoria": "Tempero",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "cebola, roxa, tempero, raiz",
    "unidade": "1kg"
  },
  {
    "produto": "Cebolinha",
    "categoria": "Tempero",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "cebolinha, tempero, raiz",
    "unidade": "maço"
  },
  {
    "produto": "Cenoura",
    "categoria": "Legume",
    "preco": 7.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "cenoura, laranja, raiz",
    "unidade": "maço"
  },
  {
    "produto": "Cheiro verde",
    "categoria": "Tempero",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "salsinha, cebolinha, tempero",
    "unidade": "maço"
  },
  {
    "produto": "Chuchu",
    "categoria": "Legume",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "chuchu, aguado, verde, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Coco Seco",
    "categoria": "Fruta",
    "preco": 6.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "fruta, coco, seco, polpa",
    "unidade": "unid"
  },
  {
    "produto": "Coco verde",
    "categoria": "Fruta",
    "preco": 5.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "fruta, água, coco",
    "unidade": "unid"
  },
  {
    "produto": "Coentro",
    "categoria": "Tempero",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "coentro, verde, tempero",
    "unidade": "maço"
  },
  {
    "produto": "Couve",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "couve, suco, verde, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Couve flor",
    "categoria": "Hortaliça",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "couve, flor, branca, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Couve Kale",
    "categoria": "Hortaliça",
    "preco": 5.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "couve, crespa, verde",
    "unidade": "maço"
  },
  {
    "produto": "Curcuma",
    "categoria": "Tempero",
    "preco": 3.1,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "curcuma, tempero, amarelo, coloral",
    "unidade": "100g"
  },
  {
    "produto": "Curcuma em pó",
    "categoria": "Tempero",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "curcuma, tempero, amarelo, coloral, pó",
    "unidade": "30g"
  },
  {
    "produto": "Erva cidreira",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "chá, cidreira, capim, santo, suco, calmante",
    "unidade": "maço"
  },
  {
    "produto": "Erva doce",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "funcho, doce, erva, chá, crocante",
    "unidade": "maço"
  },
  {
    "produto": "Ervilha a granel",
    "categoria": "Hortaliça",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "ervilha, verde, grão",
    "unidade": "1kg"
  },
  {
    "produto": "Ervilha Fresca",
    "categoria": "Hortaliça",
    "preco": 18,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "ervilha, verde, grão",
    "unidade": "100g"
  },
  {
    "produto": "Ervilha Torta",
    "categoria": "Hortaliça",
    "preco": 8.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "ervilha, vagem, torta, verde",
    "unidade": "300g"
  },
  {
    "produto": "Escarola",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "escarola, amarga, verde, hortaliças",
    "unidade": "maço"
  },
  {
    "produto": "Espinafre",
    "categoria": "Hortaliça",
    "preco": 7.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "espinafre, amargo, verde, hortaliças",
    "unidade": "maço"
  },
  {
    "produto": "Extrato de tomate",
    "categoria": "Mercearia",
    "preco": 25,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "extrato, tomate, puro",
    "unidade": "535g"
  },
  {
    "produto": "Feijão bolinha",
    "categoria": "Mercearia",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, bolinha, verde, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão branco",
    "categoria": "Mercearia",
    "preco": 13,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, branco, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão carioca",
    "categoria": "Mercearia",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, carioca, marrom, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão de corda",
    "categoria": "Mercearia",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, corda, marrom, grão",
    "unidade": "maço"
  },
  {
    "produto": "Feijão de moura rosa",
    "categoria": "Mercearia",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "feijão, rajado, vermelho, branco, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão jalo",
    "categoria": "Mercearia",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, jalo, vermelho, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão preto",
    "categoria": "Mercearia",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, preto, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão rosinha",
    "categoria": "Mercearia",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, rosinha, marrom, caldo grosso, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão roxinho",
    "categoria": "Mercearia",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "feijão, roxinho, caldo grosso, grão",
    "unidade": "500g"
  },
  {
    "produto": "Feijão vermelho",
    "categoria": "Mercearia",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "feijão, vermelho, caldo grosso, grão",
    "unidade": "500g"
  },
  {
    "produto": "Framboesa fresca",
    "categoria": "Fruta",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "framboesa, fresca, azeda",
    "unidade": "100g"
  },
  {
    "produto": "Gengibre",
    "categoria": "Tempero",
    "preco": 3.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "gengibre, chá, tempero, amarelo",
    "unidade": "100g"
  },
  {
    "produto": "Gengibre em pó",
    "categoria": "Mercearia",
    "preco": 23,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "gengibre, pó, tempero, amarelo",
    "unidade": "50g"
  },
  {
    "produto": "Goiaba Vermelha",
    "categoria": "Fruta",
    "preco": 0,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "goiaba, doce vermelha, fruta",
    "unidade": "500g"
  },
  {
    "produto": "Goiabada de colher",
    "categoria": "Fruta",
    "preco": 30,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "goiabada, goiaba, vermelha, doce, fruta",
    "unidade": "280g"
  },
  {
    "produto": "Hibisco Fresco",
    "categoria": "Mercearia",
    "preco": 16,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "hibisco, chá, vermelho",
    "unidade": "120g"
  },
  {
    "produto": "Hortelã",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "hortelã, chá, verde, tempero",
    "unidade": "maço"
  },
  {
    "produto": "Inhame",
    "categoria": "Hortaliça",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "inhame, branco, raiz",
    "unidade": "1kg"
  },
  {
    "produto": "Jabuticaba",
    "categoria": "Fruta",
    "preco": 36,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "jaboticaba, preto, fruta, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Jaca",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "jaca, fruta, doce, mole",
    "unidade": "1kg"
  },
  {
    "produto": "Jambo amarelo",
    "categoria": "Fruta",
    "preco": 30,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "jambo, fruta, perfumada, doce, amarela",
    "unidade": "1kg"
  },
  {
    "produto": "Jambolão",
    "categoria": "Fruta",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "jambolão, adistringente, amarra a boca, preta, fruta",
    "unidade": "350g"
  },
  {
    "produto": "Jiló",
    "categoria": "Hortaliça",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "jiló, legume, amargo, verde, vemelho",
    "unidade": "1kg"
  },
  {
    "produto": "Jiló branco",
    "categoria": "Hortaliça",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "jiló, legume, amargo, branco",
    "unidade": "1kg"
  },
  {
    "produto": "Kiwi",
    "categoria": "Fruta",
    "preco": 22,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "fruta, kiwi, azedo, amarelo",
    "unidade": "400g"
  },
  {
    "produto": "Laranja Bahia",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "laranja, baia, azeda, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Laranja baianinha",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "laranja, baianinha, azeda, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Laranja lima",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "laranja, lima, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Laranja pera",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "Laranja, pera, doce, azeda",
    "unidade": "1kg"
  },
  {
    "produto": "Lichia",
    "categoria": "Fruta",
    "preco": 32,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "lichia, fruta, branca, vermelha, doce, fim de ano",
    "unidade": "1kg"
  },
  {
    "produto": "Limão cravo",
    "categoria": "Fruta",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "limão, cravo, azedo, tempero, salada, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Limão Galego",
    "categoria": "Fruta",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "limão, galego, azedo, tempero, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Limão siciliano",
    "categoria": "Fruta",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "limão, siciliano, azedo, tempero, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Limão taiti",
    "categoria": "Fruta",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "limão, taiti, azedo, tempero, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Louro desidratado",
    "categoria": "Mercearia",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "louro, desidratado, tempero, mercearia",
    "unidade": "3g"
  },
  {
    "produto": "Maçã",
    "categoria": "Fruta",
    "preco": 21,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "maçã, fruta, doce, vermelha",
    "unidade": "1kg"
  },
  {
    "produto": "Mamão Formosa",
    "categoria": "Fruta",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mamão, Formosa, fruta, vermelho",
    "unidade": "1kg"
  },
  {
    "produto": "Mandioca",
    "categoria": "Hortaliça",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mandioca, raiz, mole, amarela",
    "unidade": "1kg"
  },
  {
    "produto": "Mandioca descascada",
    "categoria": "Hortaliça",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mandioca, raiz, mole, amarela, descascada",
    "unidade": "1kg"
  },
  {
    "produto": "Mandioquinha amarela",
    "categoria": "Hortaliça",
    "preco": 24,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "mandioquinha, amarela, raiz, batata, baroa",
    "unidade": "1kg"
  },
  {
    "produto": "Mandioquinha branca",
    "categoria": "Hortaliça",
    "preco": 18,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mandioquinha, branca, raiz, batata, baroa",
    "unidade": "1kg"
  },
  {
    "produto": "Manga coquinho",
    "categoria": "Fruta",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "manga, coquinho, fiapo, doce, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Manga palmer",
    "categoria": "Fruta",
    "preco": 16,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "manga, palmer, doce, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Manga tomy",
    "categoria": "Fruta",
    "preco": 10,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "manga, tomy, doce, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Manjericão",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "manjericão, tempero, manjerona, italia, molho, pesto, verde",
    "unidade": "maço"
  },
  {
    "produto": "Manjericão roxo",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "manjericão, tempero, manjerona, italia, molho, pesto, roxo",
    "unidade": "maço"
  },
  {
    "produto": "Maracujá azedo",
    "categoria": "Fruta",
    "preco": 18,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "maracujá, azedo, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Maxixe",
    "categoria": "Legume",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "maxixe, legume, pepino",
    "unidade": "1kg"
  },
  {
    "produto": "Mel silvestre 280g",
    "categoria": "Mercearia",
    "preco": 24,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mel, abelha, doce, silvestre, animal",
    "unidade": "280g"
  },
  {
    "produto": "Mel silvestre 330g",
    "categoria": "Mercearia",
    "preco": 27,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mel, abelha, doce, silvestre, animal",
    "unidade": "330g"
  },
  {
    "produto": "Mel silvestre 750g",
    "categoria": "Mercearia",
    "preco": 50,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mel, abelha, doce, silvestre, animal",
    "unidade": "750g"
  },
  {
    "produto": "Melancia (inteira, 1/2 ou 1/4)",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "melancia, vermelha, doce, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Melancia baby",
    "categoria": "Fruta",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "melancia, vermelha, doce, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Melão amarelo",
    "categoria": "Fruta",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "melão, amarelo, doce, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Melão cantalupe",
    "categoria": "Fruta",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "melão, cantaloupe, laranja, doce, suco, fruta",
    "unidade": "1kg"
  },
  {
    "produto": "Melão pele de sapo",
    "categoria": "Fruta",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "melão, pele, sapo, doce, suco, fruta, branco",
    "unidade": "1kg"
  },
  {
    "produto": "Melissa",
    "categoria": "Hortaliça",
    "preco": 4.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "melissa, calmanta, chá",
    "unidade": "maço"
  },
  {
    "produto": "Mexerica carioca",
    "categoria": "Fruta",
    "preco": 13.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "mexerica, pequena, carioca, doce, laranja",
    "unidade": "1kg"
  },
  {
    "produto": "Mexerica ponkan",
    "categoria": "Fruta",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "mexerica, grande, ponkan, doce, laranja",
    "unidade": "1kg"
  },
  {
    "produto": "Milho de pipoca 300g",
    "categoria": "Mercearia",
    "preco": 8,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "milho, pipoca, branca",
    "unidade": "300g"
  },
  {
    "produto": "Milho de pipoca preto 200g",
    "categoria": "Mercearia",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "milho, pipoca, branca",
    "unidade": "200g"
  },
  {
    "produto": "Milho verde",
    "categoria": "Hortaliça",
    "preco": 3,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "milho, verde, fresco, mole, doce",
    "unidade": "unid"
  },
  {
    "produto": "Mirtilo 100g",
    "categoria": "Hortaliça",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "mirtilo, roxo, fruta, doce",
    "unidade": "100g"
  },
  {
    "produto": "Moranga",
    "categoria": "Hortaliça",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "moranga, laranja, doce, camarão, abóbora",
    "unidade": "1kg"
  },
  {
    "produto": "Morango",
    "categoria": "Fruta",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "morango, fruta, vermelha, doce",
    "unidade": "bdj"
  },
  {
    "produto": "Moringa",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "moringa, folha, saudável, proteica",
    "unidade": "maço"
  },
  {
    "produto": "Mostarda",
    "categoria": "Hortaliça",
    "preco": 3.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "mostarda, folha, verde, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Ora pro nobis",
    "categoria": "Hortaliça",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "ora pro nobis, folha, verde, panc, tempero",
    "unidade": "maço"
  },
  {
    "produto": "Orégano desidratado",
    "categoria": "Tempero",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "orégano, tempero, desidratado",
    "unidade": "10g"
  },
  {
    "produto": "Ovo 10 unidades",
    "categoria": "Origem animal",
    "preco": 17,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "ovo, animal, galinhas, caipira, coloridos, gema laranja",
    "unidade": "cx"
  },
  {
    "produto": "Ovo 6 unidades",
    "categoria": "Origem animal",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "ovo, animal, galinhas, caipira, coloridos, gema laranja",
    "unidade": "cx"
  },
  {
    "produto": "Pão 100% integral do Céu",
    "categoria": "Mercearia",
    "preco": 18.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "pão, integral, fatiado",
    "unidade": "450g"
  },
  {
    "produto": "Pão do Céu Integral sem glúten",
    "categoria": "Mercearia",
    "preco": 21.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "pão, integral, fatiado, sem gluten",
    "unidade": "500g"
  },
  {
    "produto": "Pão fermentação natural Tartaruga",
    "categoria": "Mercearia",
    "preco": 25,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "pão, fermentação natural, tartaruga,",
    "unidade": "600g"
  },
  {
    "produto": "Patê de tofu (alho)",
    "categoria": "Mercearia",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "patê, tofu, alho",
    "unidade": "110g"
  },
  {
    "produto": "Patê de tofu (azeitona)",
    "categoria": "Mercearia",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "patê, tofu, azeitona",
    "unidade": "110g"
  },
  {
    "produto": "Patê de tofu (berinjela c/ alho)",
    "categoria": "Mercearia",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "patê, tofu, berinjela, alho",
    "unidade": "110g"
  },
  {
    "produto": "Patê de tofu (ervas finas)",
    "categoria": "Mercearia",
    "preco": 0,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "patê, tofu, ervas",
    "unidade": "110g"
  },
  {
    "produto": "Patê de tofu (salsa)",
    "categoria": "Mercearia",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "patê, tofu, salsa",
    "unidade": "110g"
  },
  {
    "produto": "Patê de tofu (tomate seco)",
    "categoria": "Mercearia",
    "preco": 11,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "patê, tofu, tomate, seco",
    "unidade": "110g"
  },
  {
    "produto": "Peixinho da horta",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "peixinho, horta, hortaliça, panc",
    "unidade": "maço"
  },
  {
    "produto": "Pepino caipira",
    "categoria": "Hortaliça",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pepino, caipira, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Pepino Japonês",
    "categoria": "Hortaliça",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pepino, japonês, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Pera",
    "categoria": "Fruta",
    "preco": 22,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pera, fruta, doce, suco",
    "unidade": "1kg"
  },
  {
    "produto": "Pimenta dedo de moça",
    "categoria": "Tempero",
    "preco": 2,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pimenta, ardida, vermelha, dedo, moça, tempero",
    "unidade": "200g"
  },
  {
    "produto": "Pimenta do Reino",
    "categoria": "Tempero",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pimenta, reino, preta, tempero, ardida",
    "unidade": "10g"
  },
  {
    "produto": "Pimenta habanero",
    "categoria": "Tempero",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pimenta, habanero, roxa, ardida, tempero",
    "unidade": "200g"
  },
  {
    "produto": "Pimentão Verde",
    "categoria": "Hortaliça",
    "preco": 20,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pimentão, verde, tempero, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Pimentão vermelho",
    "categoria": "Hortaliça",
    "preco": 25,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "pimentão, vermelho, tempero, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Pinhão",
    "categoria": "Hortaliça",
    "preco": 21,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "pinhão, grão, são joão",
    "unidade": "1kg"
  },
  {
    "produto": "Pitaya",
    "categoria": "Fruta",
    "preco": 25,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "pitaya, fruta, branca, exótica",
    "unidade": "1kg"
  },
  {
    "produto": "Poejo",
    "categoria": "Hortaliça",
    "preco": 4,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "poejo, chá, verde",
    "unidade": "maço"
  },
  {
    "produto": "Queijo Minas Frescal",
    "categoria": "Origem animal",
    "preco": 21,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "queijo, fresco, frescal, vaca, animal",
    "unidade": "350g"
  },
  {
    "produto": "Queijo Minas Padrão",
    "categoria": "Origem animal",
    "preco": 0,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "queijo, minas, vaca, animal",
    "unidade": "500g"
  },
  {
    "produto": "Quiabo",
    "categoria": "Hortaliça",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "quiabo, verde, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Rabanete",
    "categoria": "Hortaliça",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "rabanete, raiz, vermelha, ardida, salada",
    "unidade": "maço"
  },
  {
    "produto": "Radicchio",
    "categoria": "Hortaliça",
    "preco": 5.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "radicchio, hortaliça, vermelha, amarga, salada",
    "unidade": "maço"
  },
  {
    "produto": "Repolho",
    "categoria": "Hortaliça",
    "preco": 5.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "repolho, verde, cabeça, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Repolho roxo",
    "categoria": "Hortaliça",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "repolho, roxo, hortaliça, cabeça",
    "unidade": "maço"
  },
  {
    "produto": "Rucula",
    "categoria": "Hortaliça",
    "preco": 7,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "rucula, verde, ardida, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Salsão",
    "categoria": "Tempero",
    "preco": 6.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "salsão, tempero, verde",
    "unidade": "maço"
  },
  {
    "produto": "Salsinha",
    "categoria": "Tempero",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "salsinha, tempero, verde",
    "unidade": "maço"
  },
  {
    "produto": "Shitake",
    "categoria": "Hortaliça",
    "preco": 13.5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "shitake, cogumelo, marrom, fresco",
    "unidade": "200g"
  },
  {
    "produto": "Suco de maçã Integral",
    "categoria": "Mercearia",
    "preco": 25,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "suco, maçã, integral, doce,",
    "unidade": "1l"
  },
  {
    "produto": "Suco de uva integral",
    "categoria": "Mercearia",
    "preco": 18,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "suco, uva, integral, doce",
    "unidade": "1l"
  },
  {
    "produto": "Taioba",
    "categoria": "Hortaliça",
    "preco": 7.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "taioba, panc, folha, exótica, hortaliça",
    "unidade": "maço"
  },
  {
    "produto": "Tamara",
    "categoria": "Fruta",
    "preco": 68,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tamara, seca, fruta, importada",
    "unidade": "500g"
  },
  {
    "produto": "Tangerina",
    "categoria": "Fruta",
    "preco": 20,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tangerina, laranja, fruta, perfume, suco, doce",
    "unidade": "1kg"
  },
  {
    "produto": "Tofu natural",
    "categoria": "Mercearia",
    "preco": 32,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tofu, soja, natural, vegano",
    "unidade": "1kg"
  },
  {
    "produto": "Tofu natural",
    "categoria": "Mercearia",
    "preco": 16,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tofu, soja, natural, vegano",
    "unidade": "250g"
  },
  {
    "produto": "Tofu natural",
    "categoria": "Mercearia",
    "preco": 21,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tofu, soja, natural, vegano",
    "unidade": "450g"
  },
  {
    "produto": "Tomate maduro",
    "categoria": "Hortaliça",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tomate, maduro, perfeito, vermelho, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Tomate molho",
    "categoria": "Hortaliça",
    "preco": 12,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tomate, molho, vermelho, maduro, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Tomate verde",
    "categoria": "Hortaliça",
    "preco": 14,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tomate, verde, legume",
    "unidade": "1kg"
  },
  {
    "produto": "Tomatinho Grape",
    "categoria": "Hortaliça",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "tomatinho, grape, cereja, doce, vermelho",
    "unidade": "200g"
  },
  {
    "produto": "Tomatinho Grape Amarelo",
    "categoria": "Hortaliça",
    "preco": 7,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "tomatinho, grape, cereja, doce, amarelo",
    "unidade": "200g"
  },
  {
    "produto": "Tomilho",
    "categoria": "Tempero",
    "preco": 5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "tomilho, tempero, verde",
    "unidade": "maço"
  },
  {
    "produto": "Tomilho desidratado",
    "categoria": "Tempero",
    "preco": 6,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "tomilho, tempero, verde, desidratado",
    "unidade": "10g"
  },
  {
    "produto": "Toranja",
    "categoria": "Fruta",
    "preco": 9,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "toranja, grapefruit, fruta, azeda, rosa",
    "unidade": "1kg"
  },
  {
    "produto": "Uva Niágara",
    "categoria": "Fruta",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "uva, roxa, fruta, doce, niagara",
    "unidade": "500g"
  },
  {
    "produto": "Uva Vitória (sem semente)",
    "categoria": "Fruta",
    "preco": 15,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "uva, roxa, fruta, doce, vitória, sem semente",
    "unidade": "500g"
  },
  {
    "produto": "Vagem",
    "categoria": "Hortaliça",
    "preco": 30,
    "image_url": "https://picsum.photos/249",
    "status": true,
    "tag": "vagem, verde, fresca",
    "unidade": "1kg"
  },
  {
    "produto": "Vagem macarrão",
    "categoria": "Hortaliça",
    "preco": 5.5,
    "image_url": "https://picsum.photos/249",
    "status": false,
    "tag": "vagem, macarrão, verde, fininha",
    "unidade": "250g"
  }
]

/*export const asdf = () => {
  return async (dispatch) => {
    prods.map((prod) => {
      let createdAt = moment().unix()
    const id = push(child(ref(database), 'products')).key;
    const productRef = {
      id,
      createdAt,
      ...prod,
      status: prod.status ? 'true' : 'false'
    }
    set(ref(database, 'products/' + id), productRef)
    })
  }
}*/

