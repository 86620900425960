import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import OrderTable from './OrderTable';
import { start_get_checkout_by_id } from '../../../../redux/Checkout/checkout.actions';
import './Order.css'
import { useEffect } from 'react';

function Order(){
    const dispatch = useDispatch();
    const params = useParams();
    const checkoutOrder = useSelector(state => state.checkout);
    const users = useSelector(state => state.users)
    const user = users.filter(({id}) => id === checkoutOrder?.userId)

    useEffect(() => {
        if(checkoutOrder.id !== params.id){
            dispatch(start_get_checkout_by_id(params.id))
        }
    },[checkoutOrder])
    return(
        <div className="order-box">
            {checkoutOrder && user[0] && <OrderTable order={checkoutOrder} user={user[0]}/>}
        </div>
    )
}

export default Order;