function OrderTableBag({products}) {
    return(
        <div className="order-table__bag">
                {products?.map((order) => (
                    <div className={`order-table__product ${order.removed ? 'class-removed' : ''}`} key={order.id}>
                        <div className="order-table__box">
                            <div><img className="order-table__image" src={order.image_url}/></div>
                            <div className="order-table__info">
                                <div><span>Produto:</span> {order.produto}</div>
                                <div><span>Preço:</span> R$ {order.preco}</div>
                                <div><span>Quantidade:</span> {order.qtd}</div>
                                <div><span>Unidade:</span> {order.unidade}</div> 
                            </div>
                        </div>
                        <div className="order-table__total"><span>R$ {order.preco * order.qtd}</span></div>
                    </div>
                ))}
        </div>
    )
}

export default OrderTableBag;