import Logo from '../../img/logo_gdn_new.jpeg';
import { Link } from 'react-router-dom';

function Footer(){
    return(
            <div id="footer" className="footer--style-1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-auto">
							<div className="footer__item">
								<Link className="site-logo" to="/">
									<img className="img-fluid  lazy" src="img/blank.gif" data-src={Logo} alt="demo" />
								</Link>
							</div>
						</div>

						<div className="col-12 col-sm">
							<div className="row align-items-md-center no-gutters">
								<div className="col-12 col-md">
									<div className="footer__item">
										<div>
											<p>
												Sítio da Felicidade, Cosmópolis, São Paulo - SP
											</p>

											<div>
												<p>Cel | Whatsapp | +55 19 99600 7555 </p>
												<Link to="mailto:sdafelicidade@gmail.com">sdafelicidade@gmail.com</Link>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12 col-md-auto">
									<div className="footer__item">
										<div className="social-btns">
											<Link style={{opacity:'0'}} to="#"><i className="fontello-twitter"></i></Link>
											<Link style={{opacity:'0'}} to="#"><i className="fontello-facebook"></i></Link>
											<a href="https://www.instagram.com/sitio.da.felicidade/"><i className="fontello-linkedin-squared"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-5 col-xl-4 offset-xl-1">
							
						</div>
					</div>

					<div className="row flex-lg-row-reverse">
						<div className="col-12 col-lg-6">
							<div className="footer__item">
								<nav id="footer__navigation" className="navigation  text-lg-right">
									<ul>
										<li className="active"><Link to="/">Home</Link></li>
										<li><Link to="/sobre">Sobre</Link></li>
										<li><Link to="/contato">Contato</Link></li>
									</ul>
								</nav>
							</div>
						</div>

						<div className="col-12 col-lg-6">
							<div className="footer__item">
								<span className="__copy">© 2019 Agro. All rights reserved. Created by <Link className="__dev" to="https://www.facebook.com/tbrobr" target="_blank">raulbarbz</Link></span>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
}



export default Footer;