import statusFilter from '../../../../redux/Selectors/status';

function OrderTableInformation({orderState, total}) {
    return(
        <div>
                    <h6>Informações Sobre O Pedido:</h6>
                    <div className="order-table">
                        <div><span>Código do Pedido:</span> <span>{orderState.id}</span></div>
                        <div><span>Pagamento:</span> <span className="pending">{['payment', 'done'].includes(orderState.status) ? 'pago' : 'aguardando pagamento'}</span></div>
                        <div><span>Status:</span>{statusFilter(orderState.status, null).component}</div>
                        <div><span>Total:</span> <span>R${total}</span></div>    
                        <div><span>Total + Frete:</span> <span style={{'color': 'red'}}>R${total+16.5}</span></div>  
                    </div>
        </div>
    )
}

export default OrderTableInformation;