import { PRODUCTS } from './products.types';

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCTS.SET_PRODUCTS:
            return action.payload;
        case PRODUCTS.ADD_PRODUCTS:
            return action.payload;
        case PRODUCTS.ADD_PRODUCT:
            return state;
        case PRODUCTS.UPDATE_PRODUCT:
            return state.map((product) => {
                if (product.id === action.payload.id) {
                  return {
                    ...product,
                    ...action.payload
                  }
                } else {
                  return product;
                }
              })
        default: return state;
    }
};

export default reducer;