import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { start_update_product } from '../../../../redux/Products/products.actions';
import ImgComponent from './ImgComponent/ImgComponent'
import selectorProducts from '../../../../redux/Selectors/products'
import './EditPage.css';

function EditPage(){
    let params = useParams()
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [product, setProduct] = useState()

    const handleUpdate = (e) => {
        const name = e.target.name;
        const payload = e.target.value;
  
        if(name === 'preco'){
            return
        }

        let data = {}
        data[`${name}`] = payload
        
        setProduct({
            ...product,
            ...data
        })
    }

    const handleChange = (e) => {
        e.preventDefault()
        const name = e.target.name;
        let payload = e.target.value;
        
        if(name === 'preco'){
            var numberPattern = /\d+/g;

            if(!payload.match(numberPattern) && payload !== 0) return;
        }

        let data = {}
        data[`${name}`] = payload
        
        setProduct({
            ...product,
            ...data
        })
    }

    const handleSelect = (url) => {
        const name = 'image_url';
        const payload = url;

        let data = {}
        data[`${name}`] = payload
        
        setProduct({
            ...product,
            ...data
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(start_update_product(product)).then(() => navigate("/admin/products"))
    }
    
    const products = useSelector(state => state.products)
    const images = useSelector(state => state.images)
    
    useEffect(() => {
        const product_by_id = products.filter(({id}) => id === params.id)
        setProduct(product_by_id[0])
    },[products])

    return (
        <div className="edit-page">
            <div>
                <div>
                <Form className="form-editpage" onChange={(e) => {handleUpdate(e)}} onSubmit={(e)=> {handleSubmit(e)}}>
                    <Form.Group>
                        <Form.Label>Nome do Produto</Form.Label>
                        <Form.Control name="produto" type="text" placeholder={product?.produto} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>R$</Form.Label>
                        <Form.Control name="preco" type="number" value={product?.preco || ''} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Selecione a imagem clicando em uma das thumbnails abaixo:</Form.Label>
                        <Form.Control name="image_url" type="text" placeholder={product?.image_url} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select defaultValue="opt" name="status">
                            <option value="opt">Ativar/Desativar produto para venda:</option>
                            <option value='true'>Ativar</option>
                            <option value='false'>Desativar</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Adicione tags para melhores filtros de busca:</Form.Label>
                        <Form.Control name="tag" type="text" placeholder={product?.tag} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Unidade(Kilo, Dúzia, Litros...):</Form.Label>
                        <Form.Control name="unidade" type="text" placeholder={product?.unidade} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Categoria:</Form.Label>
                        <Form.Control name="categoria" type="text" placeholder={product?.categoria} />
                    </Form.Group>
                    <Button type="submit">Salvar</Button>
                </Form>
                </div>
            </div>
                <div className="edit-products">
                    {images?.map((image) => {
                        return <ImgComponent key={image.id} url={image.imageUrl} id={image.id} name={image.imageName} handleSelect={handleSelect}/>
                    })}
                </div>
        </div>
    )
}

export default EditPage