import { database } from '../../firebase/firebase';
import { ref, onValue, set, push, child } from "firebase/database";
import { SET_USER, SET_USERS, CREATE_USER } from './users.types';

export const set_user = (user) => {
    return {
        type: SET_USER,
        payload: user
    };
};

export const set_users = (users) => {
  return {
      type: SET_USERS,
      payload: users
  };
};

export const set_user_found = (user) => {
  return {
      type: SET_USER,
      payload: user
  };
};

export const create_user = (user) => {
  return {
      type: CREATE_USER,
      payload: user
  };
};

export const set_checkout_with_user = (user, checkout) => {
  return {
      type: CREATE_USER,
      payload: user
  };
};

export const start_user_login = (email, password) => {
  return (dispatch) => {
    const usersRef = ref(database, 'users/');
    onValue(usersRef, (snapshot) => {
      const user = []
      snapshot.forEach((childSnapshot) => {
        if(childSnapshot.val().email === email){
          if(childSnapshot.val().password === password){
            user.push({
              id: childSnapshot.key,
              ...childSnapshot.val()
            })
          }else{
            return 'Password Incorreto!'
          }
        }else{
          return 'E-mail não cadastrado!'
        }
      })
      return dispatch(set_user(user[0]));
    })
  }
}

export const start_create_user = (user) => {
  return (dispatch) => {
    const newUserKey = push(child(ref(database), 'users')).key;
    set(ref(database, 'users/' + newUserKey), user);
    return dispatch(create_user([{...user, id: newUserKey}]));
  }
}

export const start_set_users = () => {
  return (dispatch) => {
    const usersRef = ref(database, 'users/');
    onValue(usersRef, (snapshot) => {
      const users = []
      snapshot.forEach((childSnapshot) => {
        users.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      return dispatch(set_user(users))
    })
  }
}