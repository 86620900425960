import { GET_CHECKOUT_BY_ID, RESET_CHECKOUT, UPDATE_CHECKOUT_STATUS, UPDATE_ORDER, SET_MESSAGE, UPDATE_PRODUCTS } from './checkout.types';

const INITIAL_STATE = {}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CHECKOUT_BY_ID:
            return action.payload;
        case UPDATE_CHECKOUT_STATUS:
            return action.payload;
        case SET_MESSAGE:
            return {...state, messages: action.payload}
        case UPDATE_ORDER:
            return action.payload
        case UPDATE_PRODUCTS:
            return {...state, prods: action.payload}
        case RESET_CHECKOUT:
            return {};
        default: return state;
    }
};

export default reducer;