import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ContactForm from '../ContactForm/ContactForm';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function About(){
    return(
        <HelmetProvider>
            <Header />
			<div id="hero" className="jarallax" data-speed="0.7" data-img-position="50% 80%" style={{'backgroundImage': 'url(img/home_img/img_1.jpg)'}}>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-7">
							<h1 className="__title"><span>Sobre</span> Sítio da Felicidade</h1>

							<p>
								A história começa em Echaporã, cidade onde a Inês nasceu e trabalhou com a família na roça. Em 1997 adquiriu o sítio, que depois se tornou o Sítio da Felicidade, e desda sua origem praticou a agricultura orgânica, para consumo próprio. Teve acesso à filosofia por trás da agricultura orgânica, ficou encantada e tirou seu certificado em
								2002, cada dia mais confiante de que este é o futuro certo. Hoje passou o comando para seu filho, Felipe, que está a frente do sítio a 3 anos.
							</p>
						</div>
					</div>
				</div>
			</div>
			
			<main role="main">
				
				
				
				<section className="section section--gutter section--base-bg section--custom-02" style={{'marginPop':'20px'}}>
					<div className="container">
						<div className="section-heading" data-aos="fade">
							<h2 className="__title">Nossa <span>História</span></h2>
						</div>

						
						<div className="timeline">
							<div className="__inner">
								<div className="row">
									
									<div className="col-12 col-md-3">
										<div className="__item">
											<i className="__ico"></i>

											<div className="row">
												<div className="col-lg-11 col-xl-9">
													<p className="__year">1990</p>

													<h5 className="__title">Primeiro contato com o plantio</h5>

													<p>
														A história começa em Echaporã, cidade onde a Inês nasceu e trabalhou com a família na roça
													</p>
												</div>
											</div>
										</div>
									</div>
									
									<div className="col-12 col-md-3">
										<div className="__item">
											<i className="__ico"></i>

											<div className="row">
												<div className="col-lg-11 col-xl-9">
													<p className="__year">1997</p>

													<h5 className="__title">Sítio <br/> Adquirido</h5>

													<p>
														Em 1997 adquiriu o sítio, que depois se tornou o Sítio da Felicidade, e desda sua origem praticou a agricultura orgânica, para consumo próprio.
													</p>

													</div>
											</div>
										</div>
									</div>
									
									<div className="col-12 col-md-3">
										<div className="__item">
											<i className="__ico"></i>

											<div className="row">
												<div className="col-lg-11 col-xl-9">
													<p className="__year">2002</p>

													<h5 className="__title">Certificado agricultura orgânica</h5>

													<p>
														 Tendo certificado desde 2002 e cada dia mais confiante que este é o certo e o futuro. 
													</p>
												</div>
											</div>
										</div>
									</div>
									
									<div className="col-12 col-md-3">
										<div className="__item">
											<i className="__ico"></i>

											<div className="row">
												<div className="col-lg-11 col-xl-9">
													<p className="__year">Dias atuais</p>

													<h5 className="__title">Futuro</h5>

													<p>
														Hoje passou o comando para seu filho, Felipe, que está a frente do sítio por 3 anos.
													</p>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
					</div>
				</section>
	
				
			
				<ContactForm type='about-form'/>
			</main>
            <Footer />
			<Helmet>
				<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
				<script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js" integrity="sha512-uURl+ZXMBrF4AwGaWmEetzrd+J5/8NRkWAvJx5sbPSSuOb0bZLqf+tOzniObO00BjHa/dD7gub9oCGMLPQHtQA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
				<script type="text/javascript" src="../../js/main.min.js"></script>
				<script type="text/javascript" src="../../js/lazyLoads.js"></script>
			</Helmet> 
		</HelmetProvider>
        
    )
}

export default About;